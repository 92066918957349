import React from "react";
import { useParams } from "react-router-dom";
// pop up
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Loader from "../../components/general/loader";
// for vacancyApplication form
import VacancyApplication from "../../components/popUps/vacancyApplications";
import { API_URL } from "../../constants";
import useFetch from "../../utils/useFetch";

const Career = () => {
  const { id } = useParams();
  const { data: vacancy, error } = useFetch(`${API_URL}vacancies/${id}`);

  const ReserationPopUp = withReactContent(Swal);
  const showVacancyApplicationPopup = () => {
    ReserationPopUp.fire({
      title: "Apply for Vacancy",
      html: <VacancyApplication id={id} />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  return (
    <div className="mb-10">
      {vacancy ? (
        <>
          <div className="background-image">
            <img
              src={require("../../assets/images/Baking Factory Lazimpat-6636.jpg")}
              alt="Top bg"
            ></img>
            <h1>{vacancy.position}</h1>
          </div>
          <div className="info-box md:grid grid-cols-2 gap-4 md:px-12 px-6 py-5 mx-auto mt-12 mb-7">
            <div>
              <p className="mb-2">
                <b>Position:</b> {vacancy.position}
              </p>
              <p className="mb-2">
                <b>Department:</b> {vacancy.category}
              </p>
            </div>

            <div>
              <p className="mb-2">
                <b>Schedule:</b> {vacancy.schedule}
              </p>
              <p className="mb-2">
                <b>Outlet:</b> {vacancy.outlet.franchise.name} -{" "}
                {vacancy.outlet.name}
              </p>
            </div>
          </div>
          <div className="description md:px-12 px-6 pt-8 pb-11">
            <h2>Job Description</h2>
            <p>{vacancy.description}</p>
          </div>
          <button
            className="mx-auto block rounded-lg bg-green px-5 py-2.5 text-center text-sm font-medium text-gray-50 hover:bg-green-700 focus:outline-none sm:w-auto"
            onClick={() => {
              showVacancyApplicationPopup();
            }}
          >
            APPLY NOW
          </button>
        </>
      ) : error ? (
        <p className="pt-32 text-center">{error}</p>
      ) : (
        <p className="pt-32 text-center">
          <Loader />
        </p>
      )}
    </div>
  );
};

export default Career;
