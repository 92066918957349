import React from "react";
import { useParams } from "react-router-dom";

import Loader from "../../components/general/loader";
import Outlets from "../../components/trisara/outlets";
import { API_URL } from "../../constants";
import useFetch from "../../utils/useFetch";
import NotFound from "../general/notFound";

const Trisara = () => {
  const { id } = useParams();
  const { data: franchise, error } = useFetch(`${API_URL}franchises/${id}`);

  return (
    <>
      {franchise ? (
        <>
          <div className="px-5 lg:grid lg:grid-cols-3 lg:gap-20 lg:px-20">
            <div className="franchise-text"></div>
            <div className="franchise-text lg:h-screen lg:fixed xl:w-[25vw] lg:w-[28vw] ">
              <div className="wrapper">
                <img
                  className="pb-5 lg:w-[30vw] max-w-[70vw] mx-auto"
                  src={franchise.logo}
                  alt="logo"
                />
                <p className="mx-2 whitespace-pre-wrap text-justify">
                  {franchise.description}
                </p>
              </div>
            </div>
            <div className="relative top-[-100px] mx-auto block w-fit lg:hidden">
              <div className="scroll mx-auto block"></div>
              <p className="pt-5 text-xs font-semibold uppercase">
                Scroll Down
              </p>
            </div>
            <Outlets franchise={franchise} error={error} id={id} />
          </div>
        </>
      ) : error ? (
        <NotFound />
      ) : (
        <p className="pt-32 text-center">
          <Loader />
        </p>
      )}
    </>
  );
};

export default Trisara;
