import axios from "axios";
import React, { useEffect, useState } from "react";

import { API_URL } from "../../constants";
// notification
import { ToastNotification } from "../../utils/ToastNotification";

const Vacancy = ({ id }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  // const [outlet, setOutlet] = useState("");
  const [applicationType, setApplicationType] = useState("");

  const [selectedFile, setSelectedFile] = useState();

  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const validateSelectedFile = () => {
    const MAX_FILE_SIZE = 5120; // 5MB

    if (!selectedFile) {
      setErrorMsg("Please choose a file");
      setIsSuccess(false);
      return;
    }

    // for file extension check
    const exts = ["application/pdf", "image/jpeg"];
    const fileExtension = selectedFile.type;

    // for file limit
    const fileSizeKiloBytes = selectedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than maximum limit");
      setIsSuccess(false);
      return;
    }

    if (!exts.includes(fileExtension)) {
      setErrorMsg("Extension type not allowed");
      setIsSuccess(false);
      return;
    }

    setErrorMsg("");
    setIsSuccess(true);
  };

  useEffect(() => {
    if (selectedFile != null) {
      validateSelectedFile();
    }
  });

  const submitVacancy = (event) => {
    event.preventDefault();
    let data = new FormData();
    data.append("name", `${firstName} ${lastName}`);
    data.append("email", email);
    data.append("phone", contactNumber);
    data.append("application_type", applicationType);
    data.append("address", address);
    data.append("resume", selectedFile);
    data.append("vacancy_applied", id);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}application/`,
      headers: {
        Authorization: `Basic ${window.btoa("frontend:WWEQgN9.%AAw]GfR")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        ToastNotification.fire({
          icon: "success",
          // timer: 30000,
          title: "Vacancy Successful!",
        });
      })
      .catch((error) => {
        console.log(error);
        ToastNotification.fire({
          icon: "warning",
          // timer: 3000000,
          title: "Vacancy Failed!",
        });
      });
  };

  return (
    <form className="md:px-8 px-3" onSubmit={submitVacancy}>
      {/* FName | LName */}
      <div className="grid md:grid-cols-2 mt-5 md:gap-6">
        <div className="group relative z-0 mb-6 w-full">
          <input
            type="text"
            name="first_name"
            id="first_name"
            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0 dark:border-gray-300 dark:text-gray-500 dark:focus:border-gray-600"
            placeholder=" "
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            required
          />
          <label
            htmlFor="first_name"
            className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500 dark:text-gray-400 peer-focus:dark:text-gray-500 w-full left-0 text-left"
          >
            First name
          </label>
        </div>
        <div className="group relative z-0 mb-6 w-full">
          <input
            type="text"
            name="last_name"
            id="last_name"
            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0 dark:border-gray-300 dark:text-gray-500 dark:focus:border-gray-600"
            placeholder=" "
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            required
          />
          <label
            htmlFor="last_name"
            className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500 dark:text-gray-400 peer-focus:dark:text-gray-500 w-full left-0 text-left"
          >
            Last name
          </label>
        </div>
      </div>

      {/* Email */}
      <div className="group relative z-0 mb-6 w-full">
        <input
          type="email"
          name="email"
          id="email"
          className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0 dark:border-gray-300 dark:text-gray-500 dark:focus:border-gray-600"
          placeholder=" "
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required
        />
        <label
          htmlFor="email"
          className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500 dark:text-gray-400 peer-focus:dark:text-gray-500 w-full left-0 text-left"
        >
          Email address
        </label>
      </div>

      {/* Contact | Address */}
      <div className="grid md:grid-cols-2 md:gap-6">
        <div className="group relative z-0 mb-6 w-full">
          <input
            type="tel"
            pattern="[0-9]{10}"
            name="phone"
            id="phone"
            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0 dark:border-gray-300 dark:text-gray-500 dark:focus:border-gray-600"
            placeholder=" "
            value={contactNumber}
            onChange={(e) => {
              setContactNumber(e.target.value);
            }}
            required
          />
          <label
            htmlFor="phone"
            className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500 dark:text-gray-400 peer-focus:dark:text-gray-500 w-full left-0 text-left"
          >
            Contact Number (9812345678)
          </label>
        </div>
        <div className="group relative z-0 mb-6 w-full">
          <input
            type="text"
            name="address"
            id="address"
            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0 dark:border-gray-300 dark:text-gray-500 dark:focus:border-gray-600"
            placeholder=" "
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            required
          />
          <label
            htmlFor="address"
            className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500 dark:text-gray-400 peer-focus:dark:text-gray-500 w-full left-0 text-left"
          >
            Address
          </label>
        </div>
      </div>

      {/* Apply */}
      <div className="group relative z-0 mb-6 w-full">
        <label htmlFor="applied" className="sr-only">
          Application Type
        </label>
        <select
          id="applied"
          name="applied"
          className="peer block w-full appearance-none border-0 border-b-2 border-gray-200 bg-transparent px-0 py-2.5 text-sm text-gray-500 focus:border-gray-200 focus:outline-none focus:ring-0 dark:border-gray-300 dark:text-gray-400"
          value={applicationType}
          onChange={(e) => setApplicationType(e.target.value)}
          required
        >
          <option value="" hidden disabled>
            Select an option
          </option>
          <option value="VACANCIES">Vacancies</option>
          <option value="INTERNSHIP">Internship</option>
          <option value="TRAINING">Training</option>
          <option value="WORKSHOP">Workshop</option>
        </select>
      </div>

      {/* CV dropper */}
      <label
        htmlFor="dropzone-file"
        className="relative flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 "
      >
        <input
          id="dropzone-file"
          type="file"
          className="absolute left-0 top-0 mb-2 h-full w-full cursor-pointer text-sm font-semibold text-gray-500 dark:text-gray-400"
          accept=".pdf,.jpg,.jpeg"
          onChange={handleFileChange}
          required
        />
        <div className="flex flex-col items-center justify-center pb-6 pt-5">
          <svg
            aria-hidden="true"
            className="mb-3 h-10 w-10 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            ></path>
            strokeLinejoin
          </svg>
          <p className="text-md mb-2 font-semibold text-gray-500 dark:text-gray-400">
            Upload your CV
          </p>

          <br />
          {isSuccess ? (
            <p className="text-sm font-semibold text-green">
              Validation successful
            </p>
          ) : (
            <p className="text-sm font-semibold text-red">
              &nbsp;{errorMsg}&nbsp;
            </p>
          )}
          {/* <p className="text-sm text-red font-semibold">
            &nbsp;{errorMsg}&nbsp;
          </p> */}
          <br />
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span className="font-semibold">Click to upload</span> or drag and
            drop
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400">
            PDF or JPG (MAX. 5MB)
          </p>
        </div>
      </label>
      {isSuccess ? (
        <button
          type="submit"
          className="mx-auto mt-7 block w-full rounded-lg bg-green px-5 py-2.5 text-center text-sm font-medium text-gray-50 hover:bg-green-700 focus:outline-none sm:w-auto"
        >
          Submit
        </button>
      ) : (
        <div
          disabled
          type="submit"
          className="mx-auto mt-7 block w-full rounded-lg bg-neutral-300 px-5 py-2.5 text-center text-sm font-medium text-gray-50 focus:outline-none sm:w-fit"
        >
          Submit
        </div>
      )}
    </form>
  );
};

export default Vacancy;
