const svgAttributesGenerator = (
  totalRequired,
  startPoint,
  horizontalLengthPerLine,
  verticalLengthPerLine
) => {
  let currentHorizontalLength = horizontalLengthPerLine;
  let currentVerticalLength = verticalLengthPerLine;
  let d = `M ${startPoint.x} ${startPoint.y} H ${currentHorizontalLength} V ${currentVerticalLength}`;

  let circles = [];
  let texts = [
    {
      x: currentHorizontalLength + 10,
      y: currentVerticalLength - verticalLengthPerLine / 1.5,
      displayLength:
        currentHorizontalLength +
        currentVerticalLength -
        verticalLengthPerLine -
        horizontalLengthPerLine,
    },
  ];

  for (let i = 1; i < totalRequired; i++) {
    currentHorizontalLength += horizontalLengthPerLine;
    circles.push({
      displayLength: currentHorizontalLength + currentVerticalLength - 450,
      cx: currentHorizontalLength,
      cy: currentVerticalLength,
    });
    // console.log(
    // 	currentHorizontalLength -
    // 		horizontalLengthPerLine +
    // 		currentVerticalLength -
    // 		verticalLengthPerLine
    // );
    currentVerticalLength += verticalLengthPerLine;
    d += `H ${currentHorizontalLength} V ${currentVerticalLength}`;
    texts.push({
      x: currentHorizontalLength + 10,
      y: currentVerticalLength - verticalLengthPerLine / 1.5,
      displayLength:
        currentHorizontalLength +
        currentVerticalLength -
        verticalLengthPerLine -
        horizontalLengthPerLine,
    });
  }

  circles = circles.map((circle) => {
    circle.displayLength =
      currentHorizontalLength + currentVerticalLength - circle.displayLength;
    return circle;
  });

  texts = texts.map((text) => {
    text.displayLength =
      currentHorizontalLength + currentVerticalLength - text.displayLength;
    return text;
  });

  return {
    d,
    maxHorizontalLength: currentHorizontalLength + 200,
    maxVerticalLength: currentVerticalLength + 20,
    circles,
    texts,
  };
};

export default svgAttributesGenerator;
