import React, { useEffect, useRef } from "react";

import { API_URL } from "../../constants";
import useFetch from "../../utils/useFetch";

const Playground = () => {
  let pageRef = [useRef(null), useRef(null)];
  // const pageHomeRef = [React.createRe f(), React.createRef()];

  // Key Value Pair for Sub-Category Choices according to the backend
  // Key is the sub-category name in the backend
  // Value is the sub-category name to be displayed
  const { data: subCategoryChoices, error: subCategoryChoicesError } = useFetch(
    `${API_URL}menu/menu_sub_categories`
  );
  // console.log(subCategoryChoices);
  const scrollToRef = (ref) =>
    ref.current.scrollIntoView({ behavior: "smooth" });
  const scrollToPane = (num) => scrollToRef(pageRef[num]);

  useEffect(() => {
    if (subCategoryChoices) {
      subCategoryChoices.map((scc) => pageRef.concat("useRef(null)"));
      subCategoryChoices.map((scc) => console.log(scc.key));
    }
    console.log(pageRef);
  }, [subCategoryChoices]);
  return (
    <div className="App">
      <div className="min-h-screen grid place-content-center">
        <button
          onClick={() => {
            scrollToPane(0);
          }}
        >
          Scroll to Section 1
        </button>
        <button onClick={() => scrollToPane(1)}>Section 2</button>
      </div>
      <div className="page">
        {[0, 1].map((item, i) => (
          <div
            key={i}
            className="section1 min-h-screen grid place-content-center border border-emerald-950 "
            ref={pageRef[item]}
          >
            Section {item + 1}
            <a href="#top">go to top</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Playground;
