import React from "react";
import { Link } from "react-router-dom";
// pop up
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import JadeReservation from "../popUps/jadeReservation";
// for reservation form
import Reservation from "../popUps/reservation";

const BookHere = ({ outlet }) => {
  const ReservationPopUp = withReactContent(Swal);
  const JadeReservationPopUp = withReactContent(Swal);

  const showReservationPopup = () => {
    ReservationPopUp.fire({
      title: "Reserve a Table",
      html: <Reservation />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const showJadeReservationPopup = () => {
    JadeReservationPopUp.fire({
      html: <JadeReservation />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  return (
    <>
      <div className="book-here text-center relative">
        <img
          className="absolute top-0 h-[100%] w-[100vw] -z-10 opacity-20"
          src={require(
            outlet.franchise === "https://api.trisaranepal.com/franchises/jade/"
              ? "../../assets/images/jade room.jpg"
              : "../../assets/images/Trisara-5572.jpg"
          )}
          alt="Reservation Background"
        />
        <p className="pre-heading">Together</p>
        <h1>
          {outlet.franchise ===
          "https://api.trisaranepal.com/franchises/bakery/"
            ? "Order Cake"
            : outlet.franchise ===
                "https://api.trisaranepal.com/franchises/jade/"
              ? "Book a Room"
              : "Reservation"}
        </h1>

        {/* <p className="book-here-text w-screen px-8 md:w-[562px]">
        {outlet.book_here_description}
      </p> */}

        <button>
          {outlet.franchise ===
          "https://api.trisaranepal.com/franchises/bakery/" ? (
            <Link>Order Now</Link>
          ) : outlet.franchise ===
            "https://api.trisaranepal.com/franchises/jade/" ? (
            <Link
              onClick={() => {
                showJadeReservationPopup();
              }}
            >
              Book Now
            </Link>
          ) : (
            <Link
              onClick={() => {
                showReservationPopup();
              }}
            >
              Reserve Now
            </Link>
          )}
        </button>
      </div>
    </>
  );
};

export default BookHere;
