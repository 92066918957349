import React, { useEffect, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import emptyPlate from "../../assets/images/emptyPlate-01.png";
import Loader from "../../components/general/loader";
import { API_URL } from "../../constants";
import useAxios from "../../utils/axios/useAxios";
import useFetch from "../../utils/useFetch";
import NotFound from "../general/notFound";

const TrisaraMenu = () => {
  const { outletId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [menuItems, setMenuItems] = useState([]);
  const [menuCategories, setMenuCategories] = useState([]);
  const [menuSubCategories, setMenuSubCategories] = useState([]);

  const axios = useAxios();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/menu/", {
        params: {
          outlets: outletId,
          limit: 999,
        },
      })
      .then((response) => {
        setMenuItems(response.data.results);
      })
      .catch((error) => {});
    axios
      .get("/menu_categories/")
      .then((response) => {
        setMenuCategories(response.data);
      })
      .catch((error) => {});
    axios
      .get("/menu_subcategories/")
      .then((response) => {
        setMenuSubCategories([
          { id: 0, order: 0, title: "", description: null },
          ...response.data,
        ]);
      })
      .catch((error) => {});
    setIsLoading(false);
  }, []);

  const { data: outlet, error } = useFetch(`${API_URL}outlets/${outletId}`);

  // State for Search Text
  const [searchText, setSearchText] = useState("");

  // use refs for scroll
  const AppetiserRef = useRef(null);
  const MainCourseRef = useRef(null);
  const DessertRef = useRef(null);
  const DrinksRef = useRef(null);

  const scrollToAppetiser = () => {
    AppetiserRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToMainCourse = () => {
    MainCourseRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToDessert = () => {
    DessertRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToDrinks = () => {
    DrinksRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // Function to filter menu items by category and search text
  const filterMenuItemsByCategory = (categoryId, subCategoryId) => {
    // search text to lower case before using
    const lowerSearchText = searchText.toLowerCase();

    return menuItems
      .filter((menuItem) => {
        return (
          menuItem.menu_category.id === categoryId &&
          (subCategoryId === undefined ||
            (menuItem.menu_sub_category !== null &&
              menuItem.menu_sub_category.id === subCategoryId) ||
            (menuItem.menu_sub_category === null && subCategoryId === 0)) &&
          (lowerSearchText === "" ||
            menuItem.name.toLowerCase().includes(lowerSearchText))
        );
      })
      .sort((a, b) => a.id - b.id);
  };

  // Function to check if a category has menu items
  const categoryHasMenuItems = (categoryId, subCategoryId) => {
    return filterMenuItemsByCategory(categoryId, subCategoryId).length > 0;
  };

  // Function to get the number of chillies for a menu item
  const getItemChilly = (item) => {
    let chillies = "";
    for (let i = 0; i < item.spice_level; i++) {
      chillies += "🌶️";
    }
    return chillies;
  };

  // Function to split description into two parts
  // The function returns a list of string with the description split into two parts.
  // The first part is 380 characters long and the second part is the rest of the description.
  // However the split is done only if the description is longer than 500 characters.
  const splitDescription = (description) => {
    if (description.length < 500) return [description];
    return [description.slice(0, 380), description.slice(380)];
  };

  const MenuItemImage = ({ imageUrl, menuItemName }) => (
    <LazyLoadImage
      alt={menuItemName}
      placeholderSrc={emptyPlate}
      src={imageUrl}
      className="aspect-[5/4] w-full rounded-md object-contain"
    />
  );

  // Component for each menu item
  const MenuItem = ({ menuItem }) => (
    <div className="menu-item flex flex-row gap-2 lg:gap-5">
      <div className="menu-img w-2/5 lg:w-1/5">
        {/* {menuItem.image ? ( */}
        <MenuItemImage
          imageUrl={
            menuItem.thumbnail_url
              ? menuItem.thumbnail_url
              : menuItem.image
                ? menuItem.image
                : emptyPlate
          }
          menuItemName={menuItem.name}
        />
      </div>
      <div className="menu-data flex w-3/5 flex-col justify-center gap-1 lg:w-4/5 lg:gap-3">
        <div className="item-name text-sm font-semibold uppercase text-green lg:text-xl imac:text-6xl">
          {menuItem.name}
          <span className="text-xs lg:text-xl imac:text-5xl">
            {getItemChilly(menuItem)}
          </span>
        </div>
        {menuItem.description && (
          <div className="item-description text-xs text-new-gray-lol lg:text-base imac:text-5xl imac:leading-loose">
            {/* displaying the first part of the description */}
            <span>{splitDescription(menuItem.description)[0]}</span>
            {/* Checking and displaying the second part of the description if there is one. */}
            {splitDescription(menuItem.description)[1] && (
              <>
                {/* Read more button for showing second part of the description */}
                <span
                  id="show-more"
                  className="cursor-pointer text-sm font-bold text-green "
                  onClick={(event) => {
                    event.target.classList.toggle("hidden");
                    event.target.nextSibling.classList.toggle("hidden");
                    event.target.nextSibling.nextSibling.classList.toggle(
                      "hidden"
                    );
                  }}
                >
                  Read more
                </span>
                {/* The hidden span for the second part of the description */}
                <span className="hidden">
                  {splitDescription(menuItem.description)[1]}
                </span>
                {/* Read less button for hiding the second part of the description */}
                <span
                  id="show-more"
                  className="hidden cursor-pointer text-sm font-bold text-green"
                  onClick={(event) => {
                    event.target.classList.toggle("hidden");
                    event.target.previousSibling.classList.toggle("hidden");
                    event.target.previousSibling.previousSibling.classList.toggle(
                      "hidden"
                    );
                  }}
                >
                  Read less
                </span>
              </>
            )}
          </div>
        )}
        <div className="price text-sm imac:text-4xl font-bold">
          {/* Rs. {menuItem.price} */}
          {menuItem.prices.map((categoryPrice, index) => {
            if (index === menuItem.prices.length - 1) {
              return (
                <>
                  <p className="text-sm imac:text-4xl inline-block">
                    ||&nbsp;
                    {categoryPrice.category && categoryPrice.category !== "null"
                      ? `${categoryPrice.category} - `
                      : ""}
                    Rs. {categoryPrice.price}
                    &nbsp;
                    <div className="md:hidden inline-block">||&nbsp;</div>
                  </p>
                  <div className="md:inline-block hidden">||</div>
                </>
              );
            }
            return (
              <p className="text-sm imac:text-4xl inline-block">
                ||&nbsp;
                {categoryPrice.category && categoryPrice.category !== "null"
                  ? `${categoryPrice.category} - `
                  : ""}
                Rs. {categoryPrice.price}
                &nbsp;
                <div className="md:hidden inline-block">||&nbsp;</div>
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );

  // Function to return a list of categories with menu items
  const getCategoriesWithMenuItems = () => {
    return menuCategories.filter((menuCategory) =>
      categoryHasMenuItems(menuCategory.id)
    );
    // return categoryChoices.filter((categoryChoice) => {
    // 	return categoryHasMenuItems(categoryChoice.key);
    // });
  };

  // console.log(categoryChoices);
  // Function to return a list of sub-categories with menu items for a category
  const getSubCategoriesWithMenuItems = (menuCategory) => {
    return menuSubCategories.filter((menuSubCategory) =>
      categoryHasMenuItems(menuCategory.id, menuSubCategory.id)
    );
    // return subCategoryChoices.filter((subCategoryChoice) => {
    // 	return categoryHasMenuItems(
    // 		menuCategory.key,
    // 		subCategoryChoice.key
    // 	);
    // });
  };

  // Function to return a list of sub-categories for a category
  const getMenuSubCategories = (menuCategory) => {
    return getSubCategoriesWithMenuItems(menuCategory).map(
      (menuSubCategory) => {
        return (
          <li>
            <HashLink
              smooth
              className="text-sm imac:text-4xl imac:leading-[4.3rem]"
              to={`#${menuSubCategory.id}`}
            >
              {menuSubCategory.title}
            </HashLink>
          </li>
        );
      }
    );
  };

  return (
    <>
      {!isLoading ? (
        <div
          className="menu min-h-screen relative lg:pt-24 pt-10 imac:pt-60"
          style={{
            backgroundImage: `url(${require(
              `../../assets/images/menu-background.png`
            )})`,
            backgroundAttachment: "fixed",
          }}
        >
          <div className="flex flex-col gap-8 md:flex-row lg:w-[75vw] md:w-[85vw] mx-auto md:px-0 px-5">
            <div className="sidebar w-full md:w-1/5">
              <div className="md:fixed">
                <div className="header-title flex xl:flex-row md:flex-col flex-row lg:justify-normal justify-center">
                  <img
                    src={outlet?.logo}
                    alt="trisara"
                    className="w-2/4 md:w-[15vw] lg:w-[10vw] items-center"
                  />
                  <div className="text-2xl font-medium md:text-sm xl:text-xl self-end imac:text-6xl pl-2">
                    menu
                  </div>
                </div>
                <ul className="menu-links xl:mt-10 mt-5 flex md:flex-col flex-row gap-5 overflow-y-auto md:h-[80vh]">
                  {/* Categories are generated from the categoryChoices data */}
                  {menuCategories &&
                    getCategoriesWithMenuItems().map((category) => (
                      <li>
                        <div
                          className="text-lg imac:text-5xl imac:leading-loose cursor-pointer"
                          // href={`#${categoryChoice.key}`}
                          onClick={
                            category.title === "Appetiser"
                              ? scrollToAppetiser
                              : category.title === "Main Course"
                                ? scrollToMainCourse
                                : category.title === "Drinks"
                                  ? scrollToDrinks
                                  : category.title === "Dessert"
                                    ? scrollToDessert
                                    : null
                          }
                        >
                          {category.title}
                        </div>
                        <ul>
                          {/* Sub-Categories are generated from the subCategoryChoices data */}
                          {menuSubCategories && getMenuSubCategories(category)}
                        </ul>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="main-data w-full md:w-4/5">
              <div className="flex h-12 items-center">
                <div className="md:fixed">
                  <input
                    type="text"
                    className="h-10 md:w-[59vw] w-[90vw] mx-auto rounded-md border-[1px] border-green px-2 py-1 imac:py-8 text-sm imac:text-4xl"
                    placeholder="Search Menu"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <AiOutlineSearch className="absolute right-2 bottom-3 text-sm imac:text-[40px] font-semibold" />
                </div>
              </div>
              <div className="menu my-8">
                {/* Categories are generated from the categoryChoices data */}
                {menuCategories &&
                  getCategoriesWithMenuItems().map(
                    (category, categoryIndex) => {
                      // Only display the category if it has menu items
                      return (
                        <>
                          <div
                            ref={
                              category.title === "Appetiser"
                                ? AppetiserRef
                                : category.title === "Main Course"
                                  ? MainCourseRef
                                  : category.title === "Drinks"
                                    ? DrinksRef
                                    : category.title === "Dessert"
                                      ? DessertRef
                                      : null
                            }
                            className="menu-categories"
                            id={category.id}
                            key={category.id}
                          >
                            <h4 className="text-xs text-green lg:text-base imac:text-5xl imac:leading-loose">
                              -MENU-
                            </h4>
                            <h2 className="mb-1 text-xl lg:text-2xl imac:text-6xl imac:pb-10">
                              {category.title}
                            </h2>
                            <div className="sub-categories">
                              {/* Sub-Categories are generated from the subCategoryChoices data */}
                              {menuSubCategories &&
                                getSubCategoriesWithMenuItems(category).map(
                                  (menuSubCategory, subCategoryIndex) => {
                                    return (
                                      <>
                                        <div
                                          className="menu-subcategories"
                                          key={menuSubCategory.id}
                                        >
                                          <h5
                                            id={menuSubCategory.id}
                                            className="imac:text-4xl lg:text-lg text-sm text-right h-0 uppercase font-bold pb-3 scroll-mt-20"
                                          >
                                            {menuSubCategory.title}
                                          </h5>
                                          <div className="menu-items">
                                            {/* Menu Items are generated from the menu items of the outlet */}
                                            {/* The menu items are filtered with according to the key of the category and subcategory */}
                                            {/* The function returns a list of only filtered menu items */}
                                            {/* The list is them mapped to create componenets of each items. */}
                                            {filterMenuItemsByCategory(
                                              category.id,
                                              menuSubCategory.id
                                            ).map((menuItem, index) => (
                                              <>
                                                <MenuItem menuItem={menuItem} />
                                                {/* Showing a divider between the menu items */}
                                                {filterMenuItemsByCategory(
                                                  category.id,
                                                  menuSubCategory.id
                                                ).length -
                                                  1 !==
                                                  index && (
                                                  <hr className="my-4 border-t-[1px] border-green border-opacity-50" />
                                                )}
                                              </>
                                            ))}
                                          </div>
                                        </div>
                                        {subCategoryIndex !==
                                          getSubCategoriesWithMenuItems(
                                            category
                                          ).length -
                                            1 && (
                                          <hr className="my-4 border-[1px] border-green border-opacity-50" />
                                        )}
                                      </>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                          {/* Showing a divider between the categories */}
                          {categoryIndex !==
                            getCategoriesWithMenuItems().length - 1 && (
                            <hr className="my-4 border-[1px] border-green border-opacity-50" />
                          )}
                        </>
                      );
                    }
                  )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p className="pt-32 text-center">
          <Loader />
        </p>
      )}
    </>
  );
};

export default TrisaraMenu;
